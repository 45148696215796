<template>
  <div>
    <EmployeeDailyAttendanceTable
      :items="items"
      :is-busy="isBusyStore"
      :pagination="pagination"
      @expandDetails="handleExpand"
      @showManageModal="showManageModal"
      @showUpdateModal="showUpdateModal"
      @changePage="handlePageChange"
    ></EmployeeDailyAttendanceTable>

    <ClockRecordModal
      :is-busy="recordIsBusy"
      :records="records"
    ></ClockRecordModal>

    <TeacherAttendanceManagementModal
      :employee="employee"
      :attendance="attendance"
      @formSubmitted="fetch"
    >
    </TeacherAttendanceManagementModal>

    <TeacherAttendanceUpdateTimeModal
      :employee="employee"
      :attendance="attendance"
      @formSubmitted="fetch"
    ></TeacherAttendanceUpdateTimeModal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import dayjs from "dayjs";
import EmployeeDailyAttendanceTable from "@/modules/school/components/shared/management/attendance/table/EmployeeDailyAttendanceTable";
import TeacherAttendanceManagementModal from "@/modules/school/components/management/attendance/teacher/TeacherAttendanceManagementModal";
import ClockRecordModal from "@/modules/core/components/attendance/ClockRecordModal";
import TeacherAttendanceUpdateTimeModal from "@/modules/school/components/management/attendance/teacher/TeacherAttendanceUpdateTimeModal";

export default {
  name: "TeacherDailyAttendance",
  components: {
    TeacherAttendanceUpdateTimeModal,
    ClockRecordModal,
    TeacherAttendanceManagementModal,
    EmployeeDailyAttendanceTable,
  },
  data() {
    return {
      isBusy: false,
      recordIsBusy: false,
      attendance: {},
      employee: {},
    };
  },

  mounted() {
    this.fetch();
  },

  methods: {
    fetch() {
      this.isBusy = true;
      this.$store
        .dispatch("fetchTeacherDailyAttendanceList")
        .finally(() => (this.isBusy = false));
    },

    handlePageChange(page) {
      this.$store.commit("setTeacherDailyAttendancePage", page);
      this.fetch();
    },

    /**
     * Opens Clock Record information
     *
     * @param data
     */
    handleExpand(data) {
      console.log(data);

      if (data.item.attendance === null) {
        this.$bvModal.msgBoxOk(
          "Staff has to be checked in first for their clock records to appear",
          {
            title: "Attendance is empty",
            centered: true,
          }
        );
        return null;
      }

      this.recordIsBusy = true;
      this.$bvModal.show("clock-record-modal");

      this.$store
        .dispatch("fetchTeacherClockRecordList", {
          id: data.item.id,
          date: dayjs().format(),
        })
        .then(() => {})
        .finally(() => (this.recordIsBusy = false));
    },

    showManageModal(data) {
      console.log(data);

      this.employee = data.item;
      console.log("Employee", this.employee);

      // Create temp. attendance
      if (data.item.attendance === undefined || data.item.attendance === null) {
        console.log("Assign empty attendance");
        data.item.attendance = {
          id: null,
          date: dayjs().format(),
        };
      }

      this.attendance = data.item.attendance;
      console.log("Attendance", this.attendance);

      this.$bvModal.show("common-attendance-manage-modal");
    },

    showUpdateModal(data) {
      console.log(data);
      this.employee = data.item;
      console.log("Employee", this.employee);

      if (data.item.attendance === null) {
        this.$bvModal.msgBoxOk("Teacher doesnt have attendance", {
          title: "Attendance is empty",
          centered: true,
        });
        return null;
      }

      if (data.item.attendance.in === null) {
        this.$bvModal.msgBoxOk("Teacher doesnt have attendance", {
          title: "Attendance is empty",
          centered: true,
        });
        return null;
      }

      this.attendance = data.item.attendance;
      console.log("Attendance", this.attendance);

      this.$bvModal.show("common-attendance-update-time-modal");
    },
  },
  computed: {
    ...mapGetters({
      items: "getTeacherDailyAttendanceList",
      pagination: "getTeacherDailyAttendancePagination",
      records: "getTeacherClockRecordList",
      isBusyStore: "getTeacherDailyAttendanceBusy",
    }),
  },
};
</script>

<style scoped></style>
